import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { Car, Plane, Train } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

export interface Props {
  directions: string[]
  title?: string
}

export const Directions = memo(function Directions({
  directions,
  title,
}: Props) {
  if (!directions) {
    return null
  }

  const SVG = (icon: string) => {
    switch (icon) {
      case 'train':
        return <Train />
      case 'plane':
        return <Plane />

      default:
        return <Car />
    }
  }

  return (
    <Container dial={4} row stretch tag="section" wrap>
      <LeftSide dial={4}>
        {title ? (
          <FadeInUp>
            <Title>
              {title}
              <Line />
            </Title>
          </FadeInUp>
        ) : null}
      </LeftSide>

      <RightSide>
        <Tabs>
          <TabList>
            {directions.map((item: any, index) => (
              <Tab key={index}>
                {SVG(item.icon)}
                {item.label}
              </Tab>
            ))}
          </TabList>

          {directions.map((item: any, index) => (
            <TabPanel key={index}>
              {item.description ? (
                <TabText
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              ) : null}
            </TabPanel>
          ))}
        </Tabs>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 8.125rem 0 12.5rem;

  @media (max-width: 1199px) {
    margin: 4.375rem 0 7.5rem;
  }

  @media (max-width: 1023px) {
    margin-bottom: 5.625rem;
  }
`

const LeftSide = styled(FlexBox)`
  width: 36%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 0 9.028vw 0 10.556vw;

  @media (max-width: 1199px) {
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3.75rem 1.875rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.875rem;
`

const RightSide = styled.div`
  width: 64%;
  padding: 5rem 10.556vw 5rem 8.819vw;

  .react-tabs {
    .react-tabs__tab-list {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.125rem solid
        ${({ theme }) => theme.colors.variants.neutralLight3};
      margin: 0;
      padding: 0 3.75rem 0 0;
    }
    .react-tabs__tab {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.variants.secondaryLight};
      cursor: pointer;
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 0.9375rem;
      font-weight: 500;
      line-height: 1.5rem;
      padding-bottom: 3.75rem;
      position: relative;
      text-transform: lowercase;
      transition: 0.2s ease-out;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        color: ${({ theme }) => theme.colors.variants.neutralDark2};
      }
      &.react-tabs__tab--selected {
        color: ${({ theme }) => theme.colors.variants.neutralDark2};
        &:before {
          content: '';
          width: 100%;
          height: 0.125rem;
          background: ${({ theme }) => theme.colors.variants.neutralDark2};
          position: absolute;
          bottom: -0.125rem;
          left: 0;
        }
        svg {
          fill: ${({ theme }) => theme.colors.variants.neutralDark2};
        }
      }
      svg {
        width: auto;
        height: 2rem;
        fill: ${({ theme }) => theme.colors.variants.secondaryLight};
        margin-right: 0.75rem;
      }

      @media (max-width: 1199px) {
        padding-bottom: 3rem;
      }

      @media (max-width: 767px) {
        text-indent: -9999px;
      }
    }
    .react-tabs__tab-panel {
      display: none;
      &.react-tabs__tab-panel--selected {
        display: block;
        animation: fadeInPanel 0.6s;
      }
    }

    @keyframes fadeInPanel {
      from {
        opacity: 0;
        transform: translateY(2.25rem);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem 3.75rem 3.75rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-left: 1.875rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const TabText = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin-top: 3.75rem;

  ul {
    margin: 1em 0;
    padding: inherit;
    li {
      padding-left: 0.875rem;
      position: relative;
      &:before {
        content: '';
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark4};
        border-radius: 50%;
        position: absolute;
        top: 0.8125rem;
        left: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-top: 3rem;
  }
`
