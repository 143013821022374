import 'leaflet/dist/leaflet.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import { ChevronRight } from 'app/components/Icons'
import { useVocabularyData } from 'app/utils/vocabulary'
import L from 'leaflet'
import React, { memo, useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

export interface Props {
  address?: string
  googleMapsURL?: string
  languageCode: string
  longitude?: string
  latitude?: string
  title?: string
  zoom?: number
}

export const LeafletMap = memo(function LeafletMap({
  address,
  googleMapsURL,
  languageCode,
  longitude,
  latitude,
  title,
  zoom,
}: Props) {
  const map = useRef(null)
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [48, 60],
        iconAnchor: [24, 60],
        html: `<svg xmlns="http://www.w3.org/2000/svg" width="46.983" height="60" viewBox="0 0 46.983 60"><path d="M163.491,11.668c-12.92,0-23.491,10.18-23.491,22.708a22.233,22.233,0,0,0,4.7,13.572l17.749,23.231a1.4,1.4,0,0,0,1.827.261l.261-.261,17.749-23.231a22.234,22.234,0,0,0,4.7-13.572c0-12.529-10.571-22.708-23.491-22.708ZM161.664,43.25a9.112,9.112,0,1,1,10.7-10.7,9.2,9.2,0,0,1-7.178,10.7A8.031,8.031,0,0,1,161.664,43.25Z" transform="translate(-140 -11.668)" fill="#004371"/></svg>`,
      }),
    )
  }, [])

  return (
    <Container>
      <IconLogo />

      {title ? (
        <FadeInUp>
          <Title>
            {title}
            {address ? (
              <Address dangerouslySetInnerHTML={{ __html: address }} />
            ) : null}
            <Line />
          </Title>
        </FadeInUp>
      ) : null}

      <Map>
        {typeof window !== undefined && latitude && longitude ? (
          <MapContainer
            center={[Number(latitude), Number(longitude)]}
            dragging={L.Browser && L.Browser.mobile ? false : true}
            zoom={zoom}
            scrollWheelZoom={false}
            whenCreated={(mapInstance: any) => {
              map.current = mapInstance
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            />
            <Marker
              icon={markerIcon}
              position={[Number(latitude), Number(longitude)]}
            />
          </MapContainer>
        ) : null}
      </Map>

      {googleMapsURL ? (
        <GoogleMapsCTA href={googleMapsURL} rel="noreferrer" target="_blank">
          {useVocabularyData('open-the-map', languageCode)}
          <ChevronRight />
        </GoogleMapsCTA>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 0 8.125rem;
  padding: 6.25rem 10.556vw 0;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: 100%;
    height: calc(100% - 39vh);
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  > svg {
    width: 4.9375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-bottom: 1.875rem;
  }

  @media (max-width: 1199px) {
    margin: 5.625rem 0 6.25rem;
    padding: 4.375rem 1.875rem 0;

    > svg {
      width: 3.75rem;
      margin-bottom: 1.5rem;
    }
  }

  @media (max-width: 1023px) {
    &:before {
      height: calc(100% - 32vh);
    }
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    &:before {
      height: calc(100% - 12.5rem);
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;
  margin: auto;

  > div {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: 1.875rem auto 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }
`

const Address = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 400;
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin-top: 0.625rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-top: 0.375rem;
  }
`

const Map = styled.div`
  height: 71.1vh;
  max-height: 40rem;
  margin-top: 4.625rem;
  position: relative;

  .leaflet-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .leaflet-marker-icon {
    background: none;
    border: 0;
    svg {
      width: auto;
      height: 3.625rem;
    }
  }

  .leaflet-left {
    top: auto;
    right: 1.875rem;
    bottom: 1.875rem;
    left: auto;
    z-index: 400;
    .leaflet-control {
      display: flex;
      border: 0;
      border-radius: 0;
      margin: 0;
      a {
        width: 3rem;
        height: 3rem;
        line-height: 2.875rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        border: 0;
        border-radius: 0 !important;
        position: relative;
        text-indent: -9999px;
        transition: 0.2s ease-out;
        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          &:hover {
            background: ${({ theme }) => theme.colors.variants.neutralDark2};
            &:before,
            &:after {
              background: ${({ theme }) => theme.colors.variants.neutralLight4};
            }
          }
          &:before {
            content: '';
            width: 1.125rem;
            height: 0.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralDark2};
            position: absolute;
            top: 50%;
            left: 50%;
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }
        &.leaflet-control-zoom-in {
          margin-right: 0.5rem;
          &:after {
            content: '';
            width: 0.125rem;
            height: 1.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralDark2};
            position: absolute;
            top: 50%;
            left: 50%;
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }
        &.leaflet-disabled {
          opacity: 0.2;
          pointer-events: none;
          &:hover {
            opacity: 0.2;
          }
        }
        &:hover {
          opacity: 1;
        }
      }
    }

    @media (max-width: 1199px) {
      display: none;
    }
  }

  .leaflet-bottom {
    display: none;
  }

  @media (max-width: 1199px) {
    height: 66vh;
    margin-top: 3.75rem;
  }

  @media (max-width: 1023px) {
    height: 50vh;
  }

  @media (max-width: 767px) {
    height: 18.75rem;
  }
`

const GoogleMapsCTA = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-top: 2.5rem;
  position: relative;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
    &:after {
      right: 0.3125rem;
    }
    svg {
      margin-left: 2.625rem;
      stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
    }
  }
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    position: absolute;
    top: 0.625rem;
    right: 1.5625rem;
    left: calc(100% - 1.5625rem);
    transition: 0.3s 0.15s ease-in-out;
  }

  svg {
    width: auto;
    height: 1rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.secondaryLight};
    stroke-width: 2;
    margin-left: 2.1875rem;
    transition: 0.3s ease-in-out;
    transform: translateY(0.0625rem);
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`
