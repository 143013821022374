import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Item } from './Item'

export interface Props {
  image?: ImageProps
  list: string[]
  title?: string
}

export const Poi = memo(function Poi({ image, list, title }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container dial={4} row stretch tag="section" wrap>
      <LeftSide>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </LeftSide>
      <RightSide>
        {title ? (
          <FadeInUp>
            <Title>
              {title}
              <Line />
            </Title>
          </FadeInUp>
        ) : null}
        <List>
          {list.map((item, index) => (
            <Item key={index} {...item} />
          ))}
        </List>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 13.75rem 0 6.875rem;
  padding: 8rem 0;
  &:before {
    content: '';
    height: 100%;
    width: 76%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  @media (max-width: 1199px) {
    margin: 9.375rem 0 6.25rem;
    padding: 5.625rem 0;
  }

  @media (max-width: 1023px) {
    margin: 5.625rem 0;
    padding: 3.75rem 1.875rem;
    &:before {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const LeftSide = styled.div`
  width: calc(50% - 9.514vw);
  overflow: hidden;
  margin-right: 9.514vw;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: calc(50% - 3.75rem);
    margin-right: 3.75rem;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const RightSide = styled.div`
  width: 50%;
  padding: 7.4375rem 0;

  @media (max-width: 1199px) {
    padding: 5rem 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.875rem;
  margin-bottom: 0.4375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const List = styled.div`
  margin-top: 4.1875rem;

  @media (max-width: 1199px) {
    margin-top: 3.25rem;
  }
`
